import { Component, OnDestroy, Injector } from '@angular/core';
import { NeoStatus } from '@app/core/models/status.model';
import { BehaviorSubject, Subject } from 'rxjs';
import { AmplitudeService } from '@app/services/amplitude.service';
import { NeoLogService } from '@app/services/neo-log.service';

@Component({
    template: '',
    standalone: false
})
export class NeoBaseComponent implements OnDestroy {
  // ---------------------------------------------------------
  // Add common features here like:
  // ---------------------------------------------------------
  // Services
  public neoLogService: NeoLogService;
  public amplitudeService: AmplitudeService;
  // ---------------------------------------------------------
  // Persmissions
  // ---------------------------------------------------------
  // change / action events
  // ---------------------------------------------------------
  // Making sure subscriptions are cleaned up
  // usage:
  // someObservable$
  // .pipe(takeUntil(this.destroy$))
  // .subscribe((data) => {
  //   insert logic here...
  // });

  public NeoStatus = NeoStatus;
  public status: NeoStatus = NeoStatus.idle;
  public statusSubject: BehaviorSubject<NeoStatus> = new BehaviorSubject<NeoStatus>(this.status);
  public status$ = this.statusSubject.asObservable();

  // ---------------------------------------------------------
  // Cleanup subscriptions
  public destroy$: Subject<boolean> = new Subject<boolean>();
  // ---------------------------------------------------------

  constructor(protected injector: Injector) {
    this.neoLogService = this.injector.get(NeoLogService);
    this.amplitudeService = this.injector.get(AmplitudeService);
  }

  public setInitialising(): void {
    this.setStatus(NeoStatus.initialising);
  }

  public setLoading(): void {
    this.setStatus(NeoStatus.loading);
  }

  public setProcessing(): void {
    this.setStatus(NeoStatus.processing);
  }

  public setError(): void {
    this.setStatus(NeoStatus.loading);
  }

  public setIdle(): void {
    this.setStatus(NeoStatus.idle);
  }

  public setStatus(status: NeoStatus): void {
    this.status = status;
    this.statusSubject.next(this.status);
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  // add other common methods here as required

}
